.back1 {
    background: url('../../../../assets/img/sphere-1.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.back2 {
    background: url('../../../../assets/img/sphere-2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.back3 {
    background: url('../../../../assets/img/sphere-3.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.back4 {
    background: url('../../../../assets/img/sphere-4.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.back5 {
    background: url('../../../../assets/img/sphere-5.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.back6 {
    background: url('../../../../assets/img/sphere-6.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}