.react-multiple-carousel__arrow {
  margin-top: 15px !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
}