@import "../../assets/sass/theme.scss";

.headerBox {
    font-family: $viet;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: start;
    background: url('../../../public/media/Rectangle\ 91.svg'), lightgray -775.682px -750.993px / 151.658% 799.478% no-repeat;
    border-radius: 10px;
    border: none;
    color: white;
    // padding: #{scaleXL(24)} #{scaleXL(30)} #{scaleXL(24)} #{scaleXL(25)};
    padding: 0 #{scaleXL(40)} #{scaleXL(30)} #{scaleXL(30)};

    margin: #{scaleXL(50)} 24px;
    max-width: #{scaleXL(1630)};

    @media (max-width: 500px) {
        //
    }

    .h3 {
        font-family: $viet;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

}

.historyBox {
    font-family: $viet;
    align-items: center;
    background: #0C0E15;
    border-radius: 10px;
    border: none;
    color: white;

    margin: #{scaleXL(50)} 24px;
    max-width: #{scaleXL(1630)};

    .h3 {
        font-family: $viet;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

}




.headerText {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--white, #FFF);
    font-family: $viet;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    opacity: 0.8;


}

.crumbElement {
    font-weight: 400;
}


.timerContainer {
    background: #0C0E15;
    font-family: $viet;
    border-radius: 10px;

    //added
    display: flex;
    flex-direction: column;
    align-items: flex-start;    
    max-width: 100%;
    width: 100%;
    min-height: 1000px;
    // max-width: #{scaleXL(1600)};

    @media (max-width: 991px) {
        min-height: auto;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        min-height: 850px;
    }

    @media (min-width: 1201px) and (max-width: 1400px) {
        min-height: 900px;
    }

}

.timerContainerRight {
    background: #0C0E15;
    font-family: $viet;
    border-radius: 10px;

    //added
    display: flex;
    flex-direction: column;
    min-height: 1000px;

    @media (max-width: 991px) {
        min-height: auto;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        min-height: 850px;
    }

    @media (min-width: 1201px) and (max-width: 1400px) {
        min-height: 900px;
    }

}

.dates {
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: #{scaleXL(16)};
    margin-top: #{scaleXL(24)};
    margin-bottom: #{scaleXL(24)};
}

.date {
    @include flexCenter();
    flex-direction: column;
    width: fit-content;
    background: #10121B;
    // padding: #{scaleXL(4)} #{scaleXL(10)};
    padding: 50px;
    border-radius: #{scaleXL(8)};
    border: 1px solid #1e2332;
}


.timerBox {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    
    padding: #{scaleXL(30)};
    width: 100%;

}

.timerBox2 {    
    padding: #{scaleXL(30)};
    width: 100%;
}

.timerBoxRight {
    display: inline-flex;
    flex-direction: column;
    padding: #{scaleXL(30)};
    width: 100%;

}

.boxTitle {

    color: var(--white, #FFF);
    /* H4 */
    font-family: $viet;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.try1 {

    transform: rotate(15deg);
}

.try2 {
    transform: rotate(-15deg);
}

.numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.numbers2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;

    @media (max-width: 700px) {
        width: 50px;
        height: 50px;
    }
}

.numbersBox{
    gap: 10px;

    @media (max-width: 700px) {
        gap: initial;
    }
}

.back1 {
    background: url('../../assets/img/sphere-1.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        padding: 10px;
    }
}

.back2 {
    background: url('../../assets/img/sphere-2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        padding: 10px;
    }
}

.back3 {
    background: url('../../assets/img/sphere-3.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        padding: 10px;
    }
}

.back4 {
    background: url('../../assets/img/sphere-4.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        padding: 10px;
    }
}

.back5 {
    background: url('../../assets/img/sphere-5.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.back6 {
    background: url('../../assets/img/sphere-6.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 700px) {
        padding: 15px;
    }
}

.historyTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    // padding: #{scaleXL(30)};
    gap: 24px;

}

.historyTitleMod {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.historyId {
    color: var(--paragraph, #B1B3B9);
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #161924;
    font-weight: 400;
}

.historyTime {
    color: var(--paragraph, #B1B3B9);
    /* Paragraph 16 R */
    font-family: $viet;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 25.6px */
    // padding-left: 20px;

}

.historyFlex {
    display: inline-flex;
    flex-direction: column;

    // margin-top: 1rem;
    // margin-left: 1rem;
    padding: #{scaleXL(30)};
    gap: 10px;
}

.carouselWrapper {
    // max-width: #{scaleXL(1600)};
    position: relative;

}

.carouselWrapperPastWinning {
    position: relative;
}

.carouselNavigation {
    display: flex;
    position: absolute;
    top: 20px;
    right: 70px;
    // justify-content: flex-start;
    align-items: center;
    gap: 50px;

    @media (max-width: 550px) {
        top: 15px;
    }
}

.carouselArrowLeft {
    border-radius: 50%;
    background: #131521;

    padding: 30px;
    cursor: pointer;
    right: 15px !important;
    left: -70px !important;
}

.carouselArrowRight {
    border-radius: 50%;
    background: #131521;

    padding: 30px;
    /* Customize the background color */
    cursor: pointer;
    left: 0 !important;
}

.barImg {


    height: 1px;
    width: 100%;
    // background-image: linear-gradient(to right, #14504e 33%, transparent 33%, transparent 66%, #14504e 66%);
    background-image: linear-gradient(to right, #1B1E29 33%, transparent 33%, transparent 66%, #1B1E29 66%);
    background-size: 6px 1px;
    /* Adjust the values to control the dash length and gap */
    // margin-bottom: 1rem;
    margin-top: 1rem;

}

.dateTitle {
    @include text600();
    font-size: #{scaleXL(22)};
    color: $white;
}

.dateText {
    @include text400();
    // font-size: #{scaleXL(18)};
    color: #b1b3b9;
}


.dateTitleRight {
    color: var(--white, #FFF);
    /* H2 */
    font-family: $viet;
    font-size: #{scaleXL(30)};
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 54.6px */
}

.dateRight {
    background: #10121B;
    @include flexCenter();
    flex-direction: column;
    width: fit-content;
    padding: 35px;
    border-radius: #{scaleXL(8)};

    @media (max-width: 1400px) {
        padding: 25px;
        text-align: center;
    }

    @media (max-width: 990px) {
        padding: 60px;
        text-align: center;
    }

    @media (max-width: 767.5px) {
        padding: 18px;
        text-align: center;
    }

    @media (max-width: 550px) {
        padding: 18px;
        text-align: center;
    }
}

.dateTextRight {
    color: var(--paragraph, #B1B3B9);
    font-family: $viet;
    font-size: #{scaleXL(20)};
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 32px */
    text-transform: uppercase;
}

.buttonDark {
    text-decoration: none;
    @include flexCenter();
    justify-content: center;
    width: 100%;
    background: linear-gradient(134deg, rgba(50, 63, 220, 0.20) 0%, rgba(179, 72, 233, 0.20) 100%);
    padding: #{scaleXL(14)} 0;
    border-radius: #{scaleXL(8)};

    @include text500();
    font-size: #{scaleXL(18)};
    color: $white;
    cursor: pointer;

    &:hover {
        filter: saturate(120%) contrast(1.5) drop-shadow(2px 2px 2px #8888888a);
        color: white;
    }  
}

.buttonLight {
    text-decoration: none;
    @include flexCenter();
    justify-content: center;
    width: 100%;
    background: linear-gradient(134deg, #323fdc 0%, #b348e9 100%);
    padding: #{scaleXL(14)} 0;
    border-radius: #{scaleXL(8)};

    @include text500();
    font-size: #{scaleXL(18)};
    color: $white;
    cursor: pointer;

    &:hover {
        filter: saturate(120%) contrast(1.2) drop-shadow(2px 2px 2px #8888888a);
        color: white;
    }      
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.7;
    color: gray !important;
    font-weight: bold !important;
    filter: saturate(60%) contrast(1.2);
}

.pen {
    color: #B1B3B9;
    background: #11131D;
    border-radius: 50%;
    height: 35px;
    border: none;

    &:hover {
        color: white;
    }
}

.bet {
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #1E2332;
    background: #151720;
    color: white;
}

.numberInput {
    color: white !important;
    border-radius: 8px;
    border: 1px solid #1E2332;
    background: #0C0E15 !important;

}

.numbersRight {
    background: #10121B;
    @include flexCenter();
    flex-direction: column;
    width: fit-content;
    // padding: #{scaleXL(4)} #{scaleXL(10)};
    padding: 35px;
    border-radius: #{scaleXL(8)};

    @media (max-width: 1200px) {
        padding: 14px;
    }
}

.modalDialog {
    max-width: 500px;
    background-color: #0C0E15;
}

.numberRight {
    font-family: $viet;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.numbersModal {
    // border-radius: 20px;
    width: 100%;
    max-width: 1500px;
    background: #11131C;
    color: white;
    overflow: auto;

}

.lightButton {
    background: $lightButton;
}

.darkButton {
    background: $darkButton;
}

.actions {
    width: 100%;
    padding: #{scaleXL(30)};
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.pastWinningModal {
    background: #0C0E15;
    background-color: #0C0E15;
    padding: 15px;
    color: white;
    max-width: 1500px;
    width: 100%;
}


.pastWinningElementsStack {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    padding: 10px;
}

.pastWinningElement {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalDialogPastWinning {
    max-width: 600px;
    margin: 1.75rem auto;
    transform: none;
    position: relative;
    width: auto;
    pointer-events: none;
}

.carouselNavigationPastWinning {
    display: flex;
    position: absolute;
    align-items: center;
    gap: 50px;
    right: 60px;
    top: -70px;
}

.bigContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 991.5px) {
        gap: 2rem;
        margin-bottom: 2rem;
    }
}

.selected {
    border: rgba(179, 72, 233, 0.15);
    border-style: dashed;
    border-radius: 20px;
    padding: 30px;
    transition: all 0.5s ease;

}

.historyTitleMod2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    gap: 24px;
}

.historyTitleTickets {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    gap: 24px;
}

.maxButton{
    min-width: 100px !important;

    @media (max-width: 900px) {
        min-width: 35px !important;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
        min-width: 60px !important;
    }
}

.selectNumbersButton{
    min-width: 200px !important;
    white-space: nowrap;

    @media (max-width: 700px) {
        min-width: auto !important;
        white-space: inherit;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
        min-width: 100px !important;
    }
}

.dateRight2 {
    background: #10121B;
    @include flexCenter();
    flex-direction: column;
    width: fit-content;
    padding: 35px;
    border-radius: #{scaleXL(8)};
    min-height: 130px;

    @media (max-width: 1400px) {
        padding: 25px;
        text-align: center;
    }

    @media (max-width: 990px) {
        padding: 60px;
        text-align: center;
    }

    @media (max-width: 767.5px) {
        padding: 18px;
        text-align: center;
    }

    @media (max-width: 550px) {
        padding: 18px;
        text-align: center;
    }
}


// .dialog {
//     background-color: black;
//     color: rgba(0, 0, 0, 0.87);
//     // -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//     // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//     // border-radius: 4px;
//     // box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
//     margin: 32px;
//     position: relative;
//     overflow-y: auto;
//     // display: -webkit-box;
//     // display: -webkit-flex;
//     // display: -ms-flexbox;
//     display: flex;
//     // -webkit-flex-direction: column;
//     // -ms-flex-direction: column;
//     flex-direction: column;
//     max-height: calc(100% - 64px);
//     max-width: 600px;
// }