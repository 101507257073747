body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040719 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-responsive {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .carousel {
  width:640px;
  height:360px;
} */
.carousel {
  width: 50%;
  margin: auto;
}

/* .carousel-inner > img{
  width: 100%; 
  margin: auto;
  } */
  
  /* .carousel-inner > img{
    width: 100%;
    height: 100%; 
    margin: auto;
    } */