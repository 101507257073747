.lightButton {
    background: linear-gradient(134deg, #323FDC 0%, #B348E9 100%);
}

.darkButton {
    background: linear-gradient(134deg, rgba(50, 63, 220, 0.15) 0%, rgba(179, 72, 233, 0.15) 100%);
}

.try1 {

    transform: rotate(15deg);
}

.try2 {
    transform: rotate(-15deg);
}