/* Basic styling for the custom card */
.custom-card {
    /* border-radius: 12%; */
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
    /* padding: 10px 30px; */

    display: flex;
    justify-content: center;
    align-items: center;
}


/* Styling for the custom image */
.custom-card-img {
    /* border-radius: 12%; */
    width: 90%;
    height: 90%;
    object-fit: cover;
    transition: all 0.3s ease;
    z-index: 1;
}

/* Styling for the overlay to be shown on hover */
.custom-card-overlay {
    width: 100%;
    height: 100%;
    padding: 20px !important;
    background-color: #211534;
    /* border-radius: 12%; */
    color: #fff;
    opacity: 0;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: default;
}



/* Show the overlay on hover */
.custom-card:hover .custom-card-overlay {
    opacity: 1;
}

/* Move the image on hover */
.custom-card:hover .custom-card-img {
    transform: scale(1.1);
    border-radius: 12%;

}

.carousel-wrapper {
    width: 100%;
    /* display: flex; */
    justify-content: center;
}